import React from 'react';
import { useQuery, gql } from '@apollo/client';

import HandleLoading from '@hoc/handle-loading/handle-loading';
import MainLayout from '@hoc/layout';

import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './wp-styles.scss';


const PAGE_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        offerBy(slug: $slug) {
            content
        }
    }
`;

const PrivacyPolicy = () => {

  const { loading, error, data } = useQuery(PAGE_QUERY, {
      variables: {slug: 'wynajem-dlugoterminowy'}
  });


  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Wynajem długoterminowy'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
          <HandleLoading loading={loading} error={error}>

          <h1>Wynajem długoterminowy</h1>

            <div dangerouslySetInnerHTML={{ __html: data?.offerBy.content }}>
            </div>

            {/* <div className={s_text.double}>
              <div>
                <p>
                  Leasing All Inclusive to wynajem długoterminowy określany również jako leasing CFM, Full
                  Service Leasing czy auto na abonament. Jest to forma finansowania, która aktualnie cieszy się
                  wśród firm największym zainteresowaniem. Na jej korzyść przemawiają przede wszystkim niskie miesięczne
                  raty oraz absolutne minimum formalności, których należy dopełnić, aby móc rozpocząć korzystanie z wybranego
                  pojazdu. Wynajem długoterminowy stwarza również możliwość użytkowania samochodu wyższej klasy w cenie
                  leasingu pojazdów z tańszego segmentu.
                </p>
              </div>

              <div>
                <p>
                  Obecnie samochód stanowi jedno z podstawowych i niezbędnych dóbr każdego przedsiębiorstwa.
                  Właśnie dlatego swoim Klientom oferujemy doskonale wyposażoną flotę, w której znajdą modele
                  umożliwiające im dynamiczny rozwój prowadzonej przez nich firmy. Gwarantuję również fachową
                  pomoc i doradztwo w wyborze najkorzystniejszej oferty i ubezpieczenia, a także dopełnienie wszystkich
                  czynności niezbędnych do sfinalizowania zakupu. Kwestie, takie jak ostateczna forma finansowania
                  oraz długość trwania wynajmu zależą natomiast od indywidualnej decyzji Klienta.
                </p>
              </div>
            </div>

            <div className={s_text.double}>
              <div>
                <h2>Korzyści wynajmu długoterminowego</h2>

                <ul className={s_text.checkmark_list}>
                  <li>Pakiet ubezpieczeń, serwis oraz wymiana opon w jednej racie</li>
                  <li>Niska, stała miesięczna rata (niższa, aniżeli w przypadku klasycznego leasingu nawet o 60%)</li>
                  <li>W przypadku szkody gwarancja pojazdu zastępczego</li>
                  <li>Niższe podatki. Rata to koszt uzyskania przychodu więc można odliczyć VAT: 50% lub 100% od każdego auta oraz poprawa płynności finansowej (niskie koszty miesięczne)</li>
                  <li>Brak nieprzewidzianych wydatków związanych z eksploatacją samochodu oraz jego utrzymaniem</li>
                  <li>Możliwość pozostawienia lub wykupu samochodu po zakończeniu kontraktu lub wymiany na nowy model</li>
                  <li>Spłata jedynie utraty wartości (między ceną zakupu a wartością końcową)</li>
                </ul>
              </div>

              <div>
                <h2>Warunki wynajmu długoterminowego</h2>
                <ul className={s_text.dash_list}>
                  <li>Wpłata wstępna 0%</li>
                  <li>do 60 miesięcy</li>
                  <li>Od 3-go miesiąca prowadzenia działalności gospodarczej</li>
                  <li>Stała stopa procentowa</li>
                  <li>Minimum formalności oraz przejrzyste procedury</li>
                </ul>
                <p>
                  Jeśli chcą Państwo uzyskać więcej informacji dotyczących leasingu All Inclusive serdecznie zapraszam do
                  kontaktu telefonicznego i e-mailowego. Pozostaję do Waszej dyspozycji oraz służę swoją wiedzą i wsparciem!
                </p>
              </div>
            </div> */}

          </HandleLoading>

        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
